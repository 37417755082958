import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';

// function Footer() {
//   return (
//     <div className="footer d-flex align-items-center">
//       <span className="footer-logo">
//         encryptiest.
//       </span>
//       <Navbar className="flex-grow-1 justify-content-center">
//         <Container>
//           <Nav className="mx-auto">
//             <div className='footer-content d-flex '>
//             <Nav.Link href="/">Encrypiest 2024</Nav.Link>
//             <Nav.Link href="/careers">Careers</Nav.Link>
//             <Nav.Link href="/investors">Investors</Nav.Link>
//             <Nav.Link href="/login">Login</Nav.Link>
//             </div>
//           </Nav>
//         </Container>
//       </Navbar>
//     </div>
//   );
// }

// export default Footer;

const Footer = () => {
  return (
    <footer className="footer py-3">
      <Container className="d-flex justify-content-between align-items-center">
        <div className="footer-left">
          <div className="footer-logo">encryptiest.</div>
          <div className="footer-contact">
            <div>hello@encryptiest.com</div>
          </div>
        </div>
        <Nav className="footer-nav">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/careers">Careers</Nav.Link>
          <Nav.Link href="/investors">Investors</Nav.Link>
          <Nav.Link href="/login">Login</Nav.Link>
        </Nav>
        <div className="footer-right">
          <Link to="/advertiser-signup">
          <button className="btn btn-outline-secondary">Subscribe to Newsletter</button>
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
