import { Link } from "react-router-dom";
import heroImg from './graphic1.png';
import waveImage from './graphics/wave-haikei.svg';
import waveImage2 from '.graphics/landing-wave-2.svg';
import individual from './graphics/individual-icon.png';
import publishers from './graphics/publishers.png';
import advertisers from "./graphics/advertisers.png";
import whaleImage from './images/whaleSolution.avif'
import Header from "./header";
import Footer from "./footer";
import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { motion } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';


//react bootstrap component library
import 'bootstrap/dist/css/bootstrap.min.css';



const handleButtonClick = () => {
  console.log("click test passed!");
};

const faqData =  [
  {
      question: "What is Encryptiest?",
      answer: "Encryptiest is a privacy-focused advertising playform that respects user data and consent. "
  },
  {
      question: "How does Encryptiest protect my data?",
      answer: "We use end-to-end encryption to ensure your data is secure at all times.  "
  },
  {
      question: "Can I customize my advertising preferences?",
      answer: "Yes, users can tailor their advertising preferences to receive more relevant content."
  },
  {
      question: "How can I become a partner with Encryptiest?",
      answer: "If you're interested in becoming a partner, please contact our team via the Careers page, Advertiser signup page, and Publisher page."
  }
]

function App() {

  return (
    
  <div className="App">
    <Header/>

  {/* HERO SECTION */}

    <div className = "d-flex align-items-center justify-content-center hero-section">
     <div className = "d-flex flex-column align-items-center text-center" id = "slogan">
        <motion.div
          initial={{ y:50, opacity: 0, scale: 1}}
          animate={{ y: 0,opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          <h1>
            We do targeted <br></br>advertising, <br></br>respectfully.
          </h1>
          <p>Using Fully Homomorphic Encryption to create a better internet for all.</p>

          <Button 
              onClick={handleButtonClick}
              className = "blue-button"
          >
              Join Now
          </Button>
        </motion.div>
      </div>
    </div>

 

  <div className = "spacer layer1"></div>

        <div className="text-center reason-text d-flex flex-column align-items-center justify-content-center mx-auto">
          <button id = "reason-button">WHY ENCRYPTIEST?</button>
          <h1>We aim to <span style={{ color: "#3235FE" }}>guarantee</span> your safety while browsing.</h1>
          <p>Encryptiest uses Fully Homomorphic Encryption to create targeted ads by processing encrypted data, keeping personal information secure on the consumer's device.</p>
          <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            Who are you?  
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#individuals">Individuals</Dropdown.Item>
            <Dropdown.Item href="#advertisers">Advertisers</Dropdown.Item>
            <Dropdown.Item href="#publishers">Publishers</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        </div>

        <div className="spacer layer4"></div>



        <div className = "solution">
        <h1 className="hook">Browse with <span style={{ color: "#3235FE" }}>Safety</span> Today</h1>


        <div className="solution-section d-flex">

          <img className = "solution-media"
          src = {whaleImage}>
          </img>

            <div className = "solution-text">
              <h1 style={{color: "#3235FE"}}>Problem/Solution</h1>
              <p>Digital advertising in its current form does not protect consumers.</p> 
              <p>Using Fully Homomorphic Encryption, Encryptiest can provide targeted advertising without compromising user privacy.</p>
            </div>
        </div>
      </div>

      <div className="spacer layer5"></div>

  <div className="features-section">
    <div className="features-list">
      <div className="feature-info">
        <div className="text-content">
          <button className="reason-button">INDIVIDUALS</button>
          <h2 className="title-reason">Protect privacy, access content anywhere on any device.</h2>
          <p className="text-reason">Our data encryption solution empowers individuals by ensuring their personal information remains private and secure.</p>
        </div>
      </div>
      <div className="feature-info">
        <div className="text-content">
          <button className="reason-button">ADVERTISERS</button>
          <h2 className="title-reason">Targeting without Compromising Trust</h2>
          <p className="text-reason">Our data encryption solution empowers individuals by ensuring their personal information remains private and secure.</p>
        </div>
      </div>
      <div className="feature-info">
        <div className="text-content">
          <button className="reason-button">PUBLISHERS</button>
          <h2 className="title-reason">Secure Content Delivery for Enhanced Engagement</h2>
          <p className="text-reason">Our data encryption solution empowers individuals by ensuring their personal information remains private and secure.</p>
        </div>
      </div>
    </div>
</div>


    


    <div className="spacer layer2"></div>

      <div className = "info text-center">

        <h2>Browse with enhanced safety and privacy starting today with Encryptiest, your secure choice.</h2>
        <Button 
              onClick={handleButtonClick}
              className = "blue-button"
          >
              Join Now
          </Button>

      </div>

      <div className="spacer layer6"></div>

      <div className='faq'id = "faq-section">
        <div className="faq-title"> 
        <h1>Frequently Asked Questions</h1>
        <p>Still have unanswered questions? Feel free to email us!</p>
        </div>
                <div className = "accordion-container container faq-accordion">
                    {faqData.map((item) => (
                        <Accordion>
                            <Accordion.Header>
                                <h2>{item.question}</h2>
                            </Accordion.Header>
                            <Accordion.Body className = "text-start">
                                {item.answer}
                            </Accordion.Body>
                        </Accordion>
                      ))}
                  </div>
    

      </div>

      <Footer />

</div>



  );
}


export default App;
